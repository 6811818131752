<template>
  <div class="fixture" v-if="currentList && currentList.length">
    <div class="fixture-background">
      <img src="/images/scoretable/scoretable-bg.jpg" />
    </div>
    <div class="fixture-detail">
      <div class="fixture-logo wow fadeInDown" data-wow-delay="0.2s">
        <img src="/images/fixture/fixture-logo.png" />
      </div>
      <div class="fixture-matches">
        <div v-for="item in currentList" :key="item.id" class="fixture-item">
          <div class="row">
            <div class="col-md-1 nopadding">
              <img class="fixture-item-logo wow fadeInRight" data-wow-delay="2s"
                :src="'/images/fixture/superlig/' + item.hostTeamImage" @error="replaceByDefault" />
            </div>
            <div class="col-md-10">
              <div class="match wow fadeInUp" data-wow-delay="1s">
                <div class="row">
                  <div class="col-md-12">
                    <div class="fixture-time">{{ item.score === '-' ? item.matchTime : item.score }}</div>
                    <div class="fixture-date">{{ item.matchDate }}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="fixture-name name-left">
                      <span>{{ item.hostTeam }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="fixture-name name-right">
                      <span>{{ item.awayTeam }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1 nopadding">
              <img class="fixture-item-logo wow fadeInLeft" data-wow-delay="2s"
                :src="'/images/fixture/superlig/' + item.awayTeamImage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from "wow.js";
import store from "../store/index";
new WOW().init();
export default {
  name: "fixture",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false,
      pageNo: 0
    };
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = '/images/fixture/super-league.png'
    },
    startTimer() {
      if (!this.model || !this.allList || !this.allList.length) {
        store.dispatch('setNextPlaylist');
        return;
      }
      setTimeout(() => {
        if (this.destroyed) {
          store.dispatch('setNextPlaylist');
          return;
        }
        this.pageNo += 1;
        if (this.pageNo * 3 >= this.allList.length)
          store.dispatch("setNextPlaylist");
        else this.startTimer();
      }, 10000);
    }
  },
  computed: {
    model() {
      return this.$store.state.fixture;
    },
    allList() {
      return this.model && this.model.list ? this.model.list : [];
    },
    currentList() {
      return this.allList.slice(this.pageNo * 3, this.pageNo * 3 + 3);
    },
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
@import "../assets/animate/animate.css";
@import "../assets/animate/style.css";

.fixture-background {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
  }
}

.fixture {
  text-align: center;
  padding-top: 150px;
}

.fixture-detail {
  text-align: center;
  display: inline-block;
  position: relative;
  max-width: 1220px;
  width: 100%;
  height: auto;
  font-family: Montserrat-Bold;
  color: white;
  text-transform: uppercase;

  .fixture-logo {
    margin-bottom: 28px;
  }

  .fixture-matches {
    .fixture-item {
      margin-bottom: 38px;

      .nopadding {
        padding: 0;
      }

      .fixture-item-logo {
        width: 98px;
        height: 116px;
        object-fit: contain;
        object-position: center;
        margin-top: 86px;
      }

      .match {
        background: url("/images/fixture/fixture-item-bg.png") center top no-repeat;
        background-size: 100% auto;

        .fixture-time {
          font-size: 44px;
          line-height: 39px;
          color: white;
          display: block;
        }

        .fixture-date {
          font-size: 33px;
          line-height: 34px;
          margin-top: 5px;
          color: black;
          display: block;
        }

        .fixture-name {
          padding: 37px 0;
          color: white;
          display: block;
          text-align: center;

          span {
            font-size: 41px;
            line-height: 55px;
            height: 55px;
            -webkit-line-clamp: 1;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }

          &.name-left {
            margin-left: 56px;
            margin-right: 16px;
          }

          &.name-right {
            margin-right: 56px;
            margin-left: 16px;
          }
        }
      }
    }
  }
}
</style>
